import './image-teaser.scss';

class ImageTeaser {

    constructor(element, options) {

        const settings = {
            initAttr: 'data-imageteaser', 
            mediaAttr: 'media'
        };

        this.settings = Object.assign({}, settings, options);

        this.$imageTeaser = element;
        this.$imageMedia = this.$imageTeaser.querySelector('[' + this.settings.initAttr + '="' + this.settings.mediaAttr + '"]');

        if(this.$imageMedia === null) {
            console.error('no element found with [' + this.settings.initAttr + '="' + this.settings.mediaAttr + '"]');
            return;
        }

        this.initialize();
    }

    initialize() {
        this.setEvents();
    }

    setEvents() {

        this.$imageTeaser.addEventListener('mouseover', () => {
            this.$imageTeaser.classList.add('hover');
            this.$imageMedia.play();
        });

        this.$imageTeaser.addEventListener('mouseleave', () => {
            this.$imageMedia.pause();
            this.$imageTeaser.classList.remove('hover');
        });

        document.addEventListener('touchstart', (e) => {
            let isClickInside = this.$imageTeaser.contains(e.target);

            if (!isClickInside) {
                this.$imageTeaser.classList.remove('hover');
                this.$imageMedia.pause();
            }
        });

    }
}

export { ImageTeaser };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const context = eventDetails.$context;

    console.log(context);
    if (context) {
        const $$imageTeaser = context.querySelectorAll('[data-imageteaser="root"]');
        for (let i = 0; i < $$imageTeaser.length; i++) {

            const $imageTeaser = $$imageTeaser[i];

            const imageTeaserAPI = new ImageTeaser($imageTeaser);
            $imageTeaser.imageTeaserAPI = imageTeaserAPI;
        }
    }
});
